﻿/*
@import 'variables';
@import 'mixins';
*/

#notify-me-link {
    cursor: pointer;
}

#NotifyMeModal.modal.fade.in {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
}

#NotifyMeModal {
    .modal-content {
        display: flex;
        flex-direction: column;

        #registration-success {
            padding-left: 15px;
            padding-bottom: 15px;
            display: none;
            color: green;
        }

        #registration-failure {
            padding-left: 15px;
            padding-bottom: 15px;
            display: none;
            color: red;
        }
    }

    .modal-header {
        display: flex;
        padding-top: 10px;
        padding-bottom: 0px;
        border-bottom: none;

        button {
            margin-left: auto;
        }
    }

    .modal-body {
        margin-bottom: 5px;
        display: flex;
        width: 100%;

        #email-input {
            height: 34px;
            flex-grow: 1;
            margin-right: 10px;
        }
    }
}

.sidebar-collapse #NotifyMeModal.modal.fade.in {
    padding-left: 50px;
    .modal-dialog {
        max-width: calc(100%-50px);
    }
}

.sidebar-open #NotifyMeModal.modal.fade.in {
    padding-left: 230px;
    .modal-dialog {
        max-width: calc(100%-230px);
    }
}


