﻿@import 'mixins';

.matrix-popup {

    #product-variant-matrix {
        padding-top: 20px;

        .rs-table {
            margin-top: 0;
            width: 100%;
        }

        tr {
            background-image: none;
        }
    }

    input[type="text"] {
        border-radius: 0px;
        border: 1px solid #ccc;
    }

    .btn-group.bottom {
        margin-bottom: 20px;
    }

    .previous-product .btn-default,
    .next-product .btn-default {
        background-color: rgb(240, 240, 240);
    }

    @include toSizeSM {
        .modal-content {
            border: none;
        }

        &#center-popup .lightbox-close {
            display: none;
        }

        #product-variant-matrix {
            padding-top: 48px;

            .rs-table {
                border: none;

                td.lc-r-col2:before {
                    content: '';
                }
            }

            tr {
                padding: 4px 5px;
                border-left: none;
                border-right: none;
                border-top: none;
            }

            input[type="text"] {
                width: 100px;
                height: 40px;
                margin-bottom: 15px;
            }

            .btn-group.bottom {
                display: none;
            }
        }

        .btn-group.top {
            position: fixed;
            transform: translate3d(0px,0px,0px); /* Fix iphone position:fixed */
            margin-bottom: 1em;
            z-index: 9;
            width: 100%;
            background: #fff;
            border-bottom: 1px solid #999;
            top: -1px;
            right: 0;
            padding: 7px;
            text-align: right;

            &.btn-group > .btn {
                float: none;
            }

            &.btn-group > .btn:first-child {
                margin-right: -2px;
            }
        }

        fieldset.col-sm-12 {
            padding-right: 0;
            padding-left: 0;
        }
    }
}

a.variant-matrix-button {
    cursor: pointer;
}

.buy-info-container a#variant-matrix-button {
    padding: 10px 0;
    margin: 15px 0;
    display: inline-block;
    text-align: center;
    width: 100%;
    border-color: #888;
    font-weight: 300;
    font-family: Yanone Kaffeesatz,sans-serif;
    background: #888;
    color: #fff;
    border-radius: 0px;
    cursor: pointer;

    &:before {
        content: '\e818';
        font-family: "multicase-icons";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        width: 1em;
        margin-right: 5px;
        text-align: center;
        font-variant: normal;
        text-transform: none;
    }

    &:hover, &:focus {
        text-decoration: none;
    }

    @include fromSizeSM {
        width: 50%;
        min-width: 180px;
        height: 35px;
        line-height: 13px;
    }

    @include fromSizeMD {
        margin-left: 15px;
        max-width: 170px;
        min-width: 170px;
    }
}

.modal-dialog.modal-lg {
    max-height: 100%;
}

.hide-buy-button-for-zero-amount .hide-when-buy-selected{
    display:none;
}

.show-when-buy-selected{
    display:none;
}
.hide-buy-button-for-zero-amount .show-when-buy-selected {
    display: block;
}