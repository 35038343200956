﻿@import 'mixins';
@import 'variables';

#cart-page {
    /*@include toSizeSM() {
        padding: 0 -15px;
    }*/
}

.cart-small-links {
    width: auto;
    padding: 0;
    margin: 0;
    position: relative;
    top: -27px;

    @include fromSizeSM() {
        top: 0px;
    }
}

.cart-small-count {
    display: block;
    position: absolute;
    color: black;
    background: white;
    font-size: 12px;
    border-radius: 50%;
    min-width: 24px;
    padding-left: 3px;
    padding-right: 4px;
    height: 24px;
    text-align: center;
    line-height: 21px;
    border: 2px #3399cc solid;
    top: 3px;
    right: 0px;

    @include toSizeSM() {
        &:before,
        &:after {
            content: "";
        }
    }


    @include fromSizeSM() {
        z-index: 99;
        font-size: 14px;
        font-weight: bold;
        right: 16px;
    }
    @include fromSizeMD() {
        right: 0px;
    }
}

.btn-group > .cart-small-trigger.btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-width: 0;
    box-shadow: none;

    @include fromSizeSM() {
        border-width: 1px;
    }
}

.cart-small-togle-icon {
    display: none;
}


#mcweb-cart-cart {
    @include toSizeSM {
        h2 {
            padding: 0 0 0 5px;
            font-size: 20px;
        }

        .row-to-checkout {
            margin: 15px 0px;
        }

        .row-to-checkout a.btn.btn-primary.btn-block {
            margin-top: 25px;
            padding: 15px;
            font-size: 18px;
        }
    }
}


.cart-small-trigger {
    background: transparent;
    box-shadow: none;
    border-radius: 20px;
    border-color: #979999;
}

a.cart-small-trigger.btn.btn-default {
    height: 42px;
    font-size: 16px;
    line-height: 33px;
    border-width: 0;
    padding: 5px;

    @include toSizeSM() {
        &:active,
        &:hover,
        &:focus,
        &:visited {
            background-color: transparent;
            border-color: transparent;
            box-shadow: none;
        }
    }

    @include fromSizeSM() {
        border-width: 1px;
        border-radius: 24px;
        height: 50px;
        font-size: 16px;
        padding: 6px 15px 6px 7px;
        min-width: 230px;
    }

    @include fromSizeMD() {
        padding-right: 7px;
    }

    .cart-small-product-txt {
        font-size: 14px;
        color:#333;
        font-weight: bold;
    }
}


a.cart-small-trigger.btn.btn-default.cart-small-has-item {
    @include fromSizeSM() {     
        min-width: 0;
    }
}

.cart-small-empty,
.cart-small-items {
    color: #999;
    text-shadow: none;

    @include fromSizeSM() {
        vertical-align: top;
    }
}

.cart-small-items {
    @include fromSizeSM() {
        display: inline-block;
    }
}

.cart-small-icon {
    background: transparent;
    display: inline-block;
    width: 40px;
    height: 40px;
    vertical-align: baseline;
    position: relative;
    top: 1px;
    padding: 0;
    margin: 0;
    background: inline('images/cart.png');
    background-size: 32px;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: top;

    @include fromSizeSM() {
        width: 40px;
        color: #c1c2c0;
    }

    i {
        display: inline-block;
        font-size: 0px;
    }
}

a.cart-small-goto-checkout.btn.btn-primary {
    border-radius: 24px;
    height: 50px;
    font-size: 16px;
    line-height: 29px;
}

.cart-small-goto-checkout > span {
    vertical-align: text-top;
}

.small-cart-onpage {
    float: right;
    position: relative;
    top: 26px;
    margin-right: 54px;

    @include fromSizeSM() {
        top: 30px;
        margin-right: 0px;
    }

    @include fromSizeMD() {
    }
}

.cart-small-load {
    display: inline-block;
    width: 40px;
    height: 40px;
    position: relative;
    top: -1px;
    padding: 0;
    margin: 0;
    font-size: 25px;
}
.layoutver4 .cart-small-load {
    @include fromSizeMD() {
        min-width: inherit;
    }
}

#NewItemInCartNotifier {
    background: #3399cc;
    color: #fff;
    padding: 17px 0 0 0;
    position: fixed;
    width: 350px;
    left: calc(50% - 175px);
    top: 30%;
    border-radius: 6px;
    text-align: center;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 1);
    min-height: 80px;
    z-index: 99999999 !important;
    font-weight: normal;
    height: auto;

    .pop-wait {
        color: #fff;
        font-size: 25px;
        text-align: center;
        margin-top: 5px;
    }

    .pop-success,
    .pop-error {
        color: #fff;
        margin: 0 -9px 0px -10px;
        font-size: 35px;
        display: inline-block;
    }

    &.notifier-error {
        background-color: #f8d7da;
        border-color: #f5c6cb;
    }

    .cart-popup-text {
        font-family: 'Oswald', sans-serif;
        font-size: 18px;
        font-weight: normal;
        color: #fff;
        display: inline-block;
        vertical-align: top;
        padding: 12px 0 0 10px;
        width: 250px;
    }

    &.notifier-error .cart-popup-text,
    .pop-error {
        color: #721c24;
    }

    i.icon-ok {
        font-size: 60px;
        display: inline-block;
        margin-top: -27px;
        margin-right: -20px;
    }
}


.body-cart .UpdateControlModalContent2 {
    display: none !important;
}


.cart-small.btn-group .popover.cart-small-popover.bottom {
    margin: 0;

    @include fromSizeMD() {
        margin: 0 0 0 -3px;
    }
}

@include toSizeSM() {
    .cart-items {
        .row-to-checkout,
        .col-xs-6.col-xs-offset-0.col-sm-3.col-md-3.col-md-offset-1, /* visible: showContinueShoping */
        .row-to-checkout .col-xs-6.col-sm-3 /* visible: showRemoveCart */ {
            display: block !important;
        }
    }
}

/**** Small cart popup ****/
.cart-small-popover h3 {
    font-weight: bold;
}

.cart-small-popover .cart-small-items {
    color: $textColor;
    display: block;
}

.popover.cart-small-popover .row-line.last-row {
    width: 100%;
}

.cart-small-popover .cart-small-items,
.cart-small-popover .clearfix .col-xs-12 {
    box-sizing: border-box;
}

.cart-small-items .col-delete a {
    color: $textColor;
}


.cart-add-fav {
    margin-right: 0; 
    @include toSizeSM() {
        margin-left: 2px;
    }

    @include toSizeMD() {
        margin-left: 2px;
    }
    @include fromSizeMD() {
        margin-left: 8px;
    }
}

.cart-small .popover-content .dropdown {
    margin-bottom: 5px;
}

.cart-small-orderbook-summary .table > tbody > tr > td {
    vertical-align: middle;
}

/* Another look on the cart */
body {
    /* Cart small */

    a.cart-small-trigger.btn.btn-default {
        border: none;
        min-width: 140px;
        text-align: left;
        border-radius: 0;
        min-width: 0;

        &:hover,
        &:focus,
        &:active {
            background: none;
        }
    }

    .cart-small-empty {
        display: none !important;
    }

    .cart-small-icon {
        @include fromSizeSM() {
            &::after {
                /* content: 'Handlevogn'; */
                font-size: 14px;
                vertical-align: top;
            }
        }
    }

    .small-cart-before-sum,
    .small-cart-after-sum,
    .cart-small-product {
        display: none !important;
    }

    a.cart-small-goto-checkout.btn.btn-primary {
        display: none;
    }


    @media only screen and (min-width: 992px) {
        .cart-small-product-txt {
            display: block;
            position: absolute;
            top: 34px;
            left: 0px;
            width: 100%;
            text-align: center;
        }
    }
}