﻿@import 'mixins';
@import 'variables';

#checkout {
    @include bootstrapContainer();
    padding: 0;               
}

.body-checkout {

    /*Fix Klarna Checkout */
    nav#mmenu-push-right {
        display:none!important;
    }      

    @include toSizeSM {
        #smenu {
            display:none;
        }
                
        .container.main-inner{
            padding:0;
        }

        #checkout .col-xs-12.col-md-10{
            padding:5px;
        }
    
        
        .main-container,
        .container-fluid,
        .main-container .row {
            margin: 0;
            padding: 0;
        }

        .home {
            display: block;
        }
    }

    @include fromSizeSM {
        .login-container, 
        .top-menu,
        .HeaderSearch {
            display:none;
        }
        a.header-logo {
            top: 10px;
        }
        .home {
            display: block;
            font-size: 40px;
            padding-top: 12px;
        }
    }
    @include fromSizeMD {
        .home {
            margin-right: 100px;
        }
    }
}

.home {
    position: relative;
    display: none;
    float: right;
    right: 9px;
    font-size: 32px;
    margin: -4px 15px -15px 0px;
    color: #555;
}

.checkout {
    margin-top: 0;

    h1 {
        font-size: 24px;
    }

    h2 {
        clear: both;
    }

    .sales-message  {
        margin-bottom: 10px;
    }

    @include toSizeSM {
        padding: 0;
    
        &.empty-cart {
            text-align: center;
            .header { display:none; }
        }
        
        .header {
            margin: 0;
        }
        
        .checkout-title {
            padding: 0px 0 0px 15px;
        }

        .checkout-container {
            padding: 0;
        }

        .cart-extras {
            font-size: 14px;
        }

        .coll-delivery .checkbox {
            margin-top: 15px;
            margin-bottom: 15px;
        }

        .carriers .panel-footer {
            font-size: 12px;
            padding: 3px;
        }

        .preview-header {
            display: none;
        }

        .preview-content {
            padding: 15px 5px;
        }

        .preview-adr {
            border-width: 0px;
            padding: 0px;
        }

        .preview-delivery-right {
            margin-top: 15px;
        }

        .preview-edit {
            top: -20px;
        }

        .sales-conditions {
            margin-bottom: 10px;
            margin-top: 10px;
            display: inline-block;
        }

        #checkout-amount {
            display: block !important;
            padding-top: 0;
        }

        .action-buttons #McPaymentAddOrderbottom {
            padding: 20px 16px;
        }
    }
}

/*#cartpanel .panel-body {
    @include toSizeSM() {
        padding: 5px;
    }
}*/

.checkout-content {
    @include toSizeSM() {
        padding: 5px;
    }
}

.checkout-login {
    
    @include toSizeSM() {
        margin: 5px 0 0 0;
        padding: 0 5px;

        .email-header {
            padding-bottom: 15px;
        }

       .login-remember {
           padding-top:15px;
        }
    }
}

@include toSizeSM() {
    .body-checkout .main-container .row .checkout-psw-panel { padding-top: 15px; }

    .checkout-forgoten-psw {
            padding-top: 10px;
            padding-bottom: 10px;
    }
        
    #mcweb-shipment-shipment .panel-body {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.checkout-container .container {
    @include fromSizeSM(){
        width: 100%;
    } 
}

.payments .nav-tabs.nav-justified > li.active > a {
    @include fromSizeSM {
        min-height: 68px;
    }
}

.payments .nav-tabs .payment-nav-text {
    color: $textColor;
}

.edit-business {
    margin-top: 15px;
}

.body-checkout #m-search,
.body-checkout .container-top-menu {
    @include toSizeSM {
        display: none;
    }
}

.checkout .sales-conditions label, .checkout .sales-conditions a {
    padding-left: 0;
}

.sales-conditions-area .WebPubElement {
  width: 100% !important;
}

.f-price-txt {
    @include toSizeSM() {
        display: none;
    }
}


/**** Cart page ****/
.body-cart .PageContainerBorder {
    @include bootstrapContainer();
}

.cart-item-header,
.cart-item-header2 {
    color: $textColor;
}

.cart-item p {
    font-size: 13px;
}

.cart-item-header {
    font-size: 14px;
}

.col-amount.input-group-sm .input-group-btn .cart-item-add,
.col-amount.input-group-sm .input-group-btn .cart-item-remove {
    font-size: 14px;
    padding-top: 3px;
}

.col-amount.input-group-sm .input-quantity {
    box-shadow: none;
    font-size: 14px;
}

.body-checkout .breadcrumb-container {
    display: none;
}

.checkout-receipt-inner {
    @include toSizeSM {
        padding-left: 10px;
        padding-right: 10px;
    }
}


.session-countdown {
    text-align: center;
    padding-top: 20px;
    font-size: 16px;
}

#session-time,
#session-time-end {
    padding-top: 22px;
    text-align: center;
    font-size: 26px;
    font-weight: bold;
}

#session-time-end {
    display: none;
}

#center-popup .submit-dialog-text.session-c-footer {
    padding-top: 20px;
    padding-bottom: 0;
}

/* Fix EJ 136572 */
.cart-registration .tab-content .tab-pane {
    clear: both;
}
/* END - Fix EJ 136572 */

.info-delivery-customer {
    font-weight: bold;
    font-style: italic;
    text-align: center;
    margin: 9px 0 8px 0;
}

.cart-volume-weight-info {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
}